/* eslint-disable react/no-array-index-key */
import React from 'react'
import styled from 'styled-components'
import { FiHeart, FiCalendar, FiClock, FiPhoneCall, FiUserCheck } from 'react-icons/fi'
import { IoWalletOutline, IoHappyOutline } from 'react-icons/io5'
import { useI18n } from '@bestyled/primitives'
import mediaqueries from '../../styles/media'

export interface IArticlesBenefitsProps {
  benefits: {
    title: string
    subTitle: string

    items: {}[]
  }
  [x: string]: any
}

export function LandingBenefits<IArticlesBenefitsProps>({ benefits, ...rest }) {
  const { items } = benefits
  const { t } = useI18n()

  return (
    <BenefitsSection className='container'>
      <BenefitsHeader className='row'>
        <BenefitsTitle className='col-12'>{t('benefits.title')}</BenefitsTitle>
        <BenefitsSubHeading className='col-12'>{t('benefits.subTitle')}</BenefitsSubHeading>
      </BenefitsHeader>
      <div className='row'>
        {items.map((item, index) => {
          return (
            <div key={index} className='col-4 col-sm-6'>
              <ArticlesCard item={item} index={index}></ArticlesCard>
            </div>
          )
        })}
      </div>
    </BenefitsSection>
  )
}

interface ArticlesCardProps {
  item: {
    name: string
    icon: string
    description: string
    [x: string]: any
  }
  index: number
}

const ArticlesCard = ({ item, index }: ArticlesCardProps) => {
  const { t } = useI18n()
  const prefix = `benefits.items.${index}`

  let Icon
  switch (item.icon) {
    case 'heart':
      Icon = <FiHeart />
      break
    case 'calendar':
      Icon = <FiCalendar />
      break
    case 'clock':
      Icon = <FiClock />
      break
    case 'mobile':
      Icon = <FiPhoneCall />
      break
    case 'wallet':
      Icon = <IoWalletOutline />
      break
    case 'happy':
      Icon = <IoHappyOutline />
      break

    default:
      Icon = <FiUserCheck />
      break
  }

  return (
    <Card item={item} index={index}>
      <CardIcon>{Icon}</CardIcon>
      <CardTitle>
        <a href='#'>{t(`${prefix}.name`)}</a>
      </CardTitle>
      <CardText>{t(`${prefix}.description`)}</CardText>
    </Card>
  )
}

const BenefitsSection = styled.section`
  z-index: 5;
  padding-top: 4em;
  padding-bottom: 4em;

  * {
    z-index: 5;
  }
  .row {
    @media only screen and (min-width: 60em) {
      width: 100%;
      margin-left: 5%;
    }
  }

  .row [class^='col'] {
    @media only screen and (min-width: 60em) {
      margin: 15px 1.2% 0 !important;
    }
  }
`

const BenefitsHeader = styled.header`
  font-family: ${(p) => p.theme.fonts.header};
  font-size: ${(p) => p.theme.fonts.sizes[7]}px;
  font-weight: 400;
  line-height: 1.9;
  color: #757575;
  box-sizing: border-box;
  display: block;
  text-align: center;
  letter-spacing: 0.5px;
  margin: 0 auto 70px;
`

const BenefitsTitle = styled.h2`
  text-align: center;
  box-sizing: border-box;
  margin-top: 0;
  margin-bottom: 0.5em;
  font-family: Dosis, sans-serif;
  line-height: 1.5;
  font-size: ${(p) => p.theme.fontSizes[6]}px;
  font-weight: 400;
  font-family: ${(p) => p.theme.fonts.header};
  color: ${(p) => p.theme.colors.primary};
  letter-spacing: 0.5px;
  font-weight: 400;
`

const BenefitsSubHeading = styled.h4`
  font-family: ${(p) => p.theme.fonts.paragraph};

  line-height: 1.9;
  color: #757575;
  text-align: center;
  box-sizing: border-box;
  margin-top: 0;
  margin-bottom: 1.3em;
  font-weight: 300;
  font-size: ${(p) => p.theme.fontSizes[3]}px;
`

const Card = styled.div<ArticlesCardProps>`
  font-family: ${(p) => p.theme.fonts.paragraph};

  font-weight: 300;
  line-height: 1.9;
  box-sizing: border-box;
  position: relative;
  display: flex;
  flex-direction: column;
  min-width: 0;
  word-wrap: break-word;
  background-clip: border-box;
  border-radius: 0.25rem;
  flex: 1 1 auto;
  padding: 15px;
  text-align: center !important;
  border: 0;
  transition: 0.5s;
  box-shadow: 0 0 12px rgba(0, 0, 0, 0.06);
  color: rgba(255, 255, 255, 0.85) !important;
  background-color: ${(p) => (p.index % 2 === 0 ? '#8a3965' : '#26284b')};
  height: 300px;
`
const CardIcon = styled.p`
  font-size: ${(p) => p.theme.fontSizes[6] + 5}px;
  font-family: ${(p) => p.theme.fonts.serif};

  font-weight: ${(p) => p.theme.fonts.weights.normal};
  line-height: 1.9;
  word-wrap: break-word;
  text-align: center !important;
  box-sizing: border-box;
  margin: 3% auto !important ;
  color: rgba(255, 255, 255, 0.85);
  i {
    word-wrap: break-word;
    text-align: center !important;
    color: rgba(255, 255, 255, 0.85);
    box-sizing: border-box;
    font-family: ${(p) => p.theme.fonts.header};

    speak: none;
    font-style: normal;
    font-weight: ${(p) => p.theme.fontWeights.normal};
    font-variant: normal;
    text-transform: none;
    line-height: 1;
    -webkit-font-smoothing: antialiased;
    display: inline-block;
    font-size: ${(p) => p.theme.fontSizes[6] + 5}px;
  }
  ${mediaqueries.desktop`
    font-size: ${(p) => p.theme.fontSizes[6] + 5}px;
    margin: 1% auto !important ;
      

  `};
  ${mediaqueries.tablet`
    font-size: ${(p) => p.theme.fontSizes[6] + 5}px;
    margin: 2% auto !important ;


  `};
`
const CardTitle = styled.h5`
  word-wrap: break-word;
  text-align: center !important;
  box-sizing: border-box;
  margin-top: 0;
  font-family: ${(p) => p.theme.fonts.header};
  line-height: 1.5;
  font-size: ${(p) => p.theme.fontSizes[3]}px;

  letter-spacing: 0.5px;
  margin-bottom: 1.5rem !important;
  font-weight: ${(p) => p.theme.fontWeights.heavy} !important;
  color: #fff;
  ${mediaqueries.desktop_medium`
     font-size: ${(p) => p.theme.fontSizes[2]}px;

  `};
  ${mediaqueries.tablet`
     font-size: ${(p) => p.theme.fontSizes[3]}px;
  `};
`
const CardText = styled.p`
  font-family: ${(p) => p.theme.fonts.paragraph};

  font-size: ${(p) => p.theme.fontSizes[1] + 1}px;

  font-weight: ${(p) => p.theme.fontWeights.normal};

  line-height: 1.9;
  word-wrap: break-word;
  text-align: center !important;
  box-sizing: border-box;
  margin-top: 0;
  margin-bottom: 1rem;
  color: rgba(255, 255, 255, 0.85);
  ${mediaqueries.desktop_medium`
     font-size: ${(p) => p.theme.fontSizes[1] - 1}px;

  `};
  ${mediaqueries.tablet`
     font-size: ${(p) => p.theme.fontSizes[1] + 1}px;

  `};
`
