import * as React from 'react'
import styled, { useTheme } from 'styled-components'

// import { useI18n } from '@bestyled/primitives'
// import { Headings, Section, mediaqueries } from '@slipway/theme-components'
import SEO from '../components/SEO'
import Layout from '../components/Layout'
import HeroLanding from '../sections/Landing/Landing.Hero'
import { LandingBenefits } from '../sections/Landing/Landing.Benefits'
import { LandingTestimonials } from '../sections/Landing/Landing.Testimonials'

// import Paginator from '../components/Navigation.Paginator'

// import ArticlesHero from '../sections/articles/Articles.Hero'
// import ArticlesList from '../sections/articles/Articles.List'

const LandingPage = ({ location, pageContext }) => {
  const theme = useTheme()
  console.debug('context', pageContext)
  console.debug('theme', theme)

  return (
    <Layout
      {...pageContext}
      location={location}
      basePath={pageContext.basePath}
      locale={pageContext.locale}
      languages={pageContext.languages}
      translations={pageContext.translations}
    >
      <SEO pathname={location.pathname} />
      <HeroLanding context={pageContext}></HeroLanding>
      <LandingBenefits {...pageContext} benefits={pageContext.translations.benefits} theme={theme} />
      <LandingTestimonials {...pageContext} testimonials={pageContext.translations.testimonials} theme={theme} />
    </Layout>
  )
}
export default LandingPage


